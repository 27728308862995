@keyframes neon-1 {
	from {
		box-shadow: 0 0 0 #ff3d3d;
	}
	to {
		box-shadow: 0 0 20px #ff3d3d;
	}
}

.alerta-chamado-1 {
	animation: neon-1 0.5s alternate infinite ease-in-out;
}
